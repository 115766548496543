<template>
  <v-container class="pa-0" fluid style="height: 100vh">
    <v-row class="h-100" no-gutters>
      <v-col
        class="hidden-sm-and-down"
        md="7"
        style="background-color: #0d01c7"
      >
        <img
          src="@/assets/img/bg.jpg"
          width="100%"
          height="100%"
          alt="bg-image"
          style="width: 100%; height: 100%"
        />
      </v-col>
      <v-col cols="12" sm="8" md="5" class="mx-auto relative">
        <notification-message
          :message.sync="message"
          :type="error && message ? 'error' : 'success'"
        />
        <v-container class="d-flex align-center justify-center h-100">
          <v-card
            width="340"
            flat
            class="text-center animate__animated animate__zoomIn"
          >
            <v-col>
              <a
                class="black--text text-subtitle-1 d-flex justify-center align-center mb-4"
                href="https://snappyexchange.com"
              >
                <img :src="logoSnappy" width="45" height="45" />
                <span class="font-weight-bold">SnappyExchange</span>
              </a>
              <h3>Oh!</h3>
              <h5 class="font-weight-regular">
                Enter your email to reset your password
              </h5>
            </v-col>
            <v-col>
              <v-form ref="resetPasswordForm" @submit.prevent="validate">
                <v-container>
                  <v-text-field
                    :disabled="loading"
                    name="email"
                    type="email"
                    outlined
                    rounded
                    hint="Your snappy exchanged registered email"
                    prepend-inner-icon="mdi-email"
                    label="Email"
                    v-model="email"
                    :rules="[rules.required, rules.email]"
                  ></v-text-field>

                  <v-btn
                    class="mt-5"
                    block
                    rounded
                    :loading="loading"
                    color="primary"
                    elevation="2"
                    type="submit"
                    >Reset password</v-btn
                  >
                  <div class="d-flex justify-end mt-5">
                    <router-link
                      class="grey--text text--darken-2 text-decoration-none text-subtitle-2"
                      to="/login"
                      >Back to login</router-link
                    >
                  </div>
                </v-container>
              </v-form>
            </v-col>
          </v-card>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import logoSnappy from '../../assets/logo.png'
import notificationMessage from '../../components/notification/notificationMessage.vue'
import api from '../../api/users/auth'
import rules from '../../utils/rules'

export default {
  components: { notificationMessage },
  data() {
    return {
      error: false,
      message: null,
      loading: false,
      logoSnappy,
      email: '',
      rules: {
        required: rules.required,
        email: rules.email
      }
    }
  },
  methods: {
    validate() {
      if (this.$refs.resetPasswordForm.validate()) this.sendResetMail()
    },
    async sendResetMail() {
      this.loading = true
      const res = await api.auth().resetPasswordEmail(this.email)

      if (res.error) {
        // this.error = true
        // this.message = res.errorMessage.message
        this.loading = false
        this.$swal.fire({
          icon: 'error',
          title: 'Error',
          text: res.errorMessage.message
        })
        return
      }

      this.message = res.data.data
      this.error = false
      this.loading = false
    }
  },
  created() {
    this.email = this.$route.query.email
  }
}
</script>

<style></style>
